<template>
<div>
    <div>
        <vc-header title="Anexos" />

        <v-card class="mt-1 pt-4 pb-3" style="max-height: 450px;  overflow-y: auto;" v-if="!usrExtern">
            <v-row style="margin:auto">
                <v-col lg="12" cols="12" class="s-col-text">
                    <v-row>
                        <v-col cols="12" lg="6" v-for="(item, index) in itemsAttach" :key="index" class="s-col-form">

                            <v-file-input show-size :ref="'attach' + item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                <template slot="append" v-if="item.RqdRequired == 1">
                                    <label style="color: red">Obligatorio</label>
                                </template>
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="2">
                        <v-data-table class="" fixed-header="" dense :items-per-page="-1" disable-sort hide-default-footer :headers="headersFiles" :items="attachs">
                            <template v-slot:item.RqdAttach="{ item }">
                                <v-icon color="red" @click="deleteFile(item)">mdi-close</v-icon>
                                <v-icon color="green" @click="downloadFile(item)">mdi-download</v-icon>
                                {{ item.RqdAttach }}
                            </template>
                            <template v-slot:item.SecCreate="{ item }">
                                {{$fun.formatDateTimeView(item.SecCreate)}}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <vc-footer style="box-shadow: -2px 15px 15px 11px" save @save="save()" close @close="$emit('close')">
        </vc-footer>
    </div>
</div>
</template>

<script>
import _AttachConfiguration from "@/services/General/GenAttachConfigurationService";
import _sHelper from "@/services/HelperService.js";

export default {
    name: '',
    props: {
        config: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            itemsAttach: [],
            headersFiles: [{
                    text: "Archivo",
                    value: "RqdAttach",
                },
                {
                    text: "Creado Por",
                    value: "UsrName",
                },
                {
                    text: "Fec. Creaciòn",
                    value: "SecCreate",
                },
            ],
            itemsReponse: [],
            attachs: [],
            usrExtern:false,
        };
    },

    created() {

        this.usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))

        console.log("created", this.config);

        this.intitialize()
        this.initializeValue()

    },

    computed: {
        propID() {
            for (var prop in this.config)
                if (this.config[prop] == "ID") return prop;
            return null;
        },
    },

    methods: {
        intitialize() {
            _AttachConfiguration.list({
                TrsCode: this.config.TrsCode,
                CpyID: this.$fun.getCompanyID()
            }, this.$fun.getUserID()).then((resp) => {
                this.itemsAttach = resp.data.Result;
            });
        },

        initializeValue() {
            const requestData = {
                [this.propID]: this.config.DocEntry
            };
            console.log("requestData", requestData);

            this.config.service
                .byID(requestData, this.$fun.getUserID())
                .then((r) => {

                    this.attachs = r.data.Result !== null ? r.data.Result.itemsAttachs : []
                });
        },

        save() {

            

            this.$fun.uploadFiles(this.itemsAttach, 1).then(resp => {
                if (resp.status == 200) {

                    this.itemsReponse = resp.data

                    this.itemsReponse.forEach(element => {
                        element[this.propID] = this.config.DocEntry
                        element.SecStatus = 1;
                        element.UsrCreateID = this.$fun.getUserID();
                    });


                    if (this.itemsReponse.length > 0) {
                        this.config.service.savefiles(this.itemsReponse).then(r => {
                            if (r.status == 200) {
                                this.initializeValue()
                            }

                        })
                    }

                }

            })
        },

        deleteFile(item) {

            item.SecStatus = 0;
            item.UsrUpdateID = this.$fun.getUserID();

            this.config.service
                .savefiles([item])
                .then((r) => {
                    this.initializeValue()
                });
        },

        downloadFile(item) {
            if (item == null) return;
            _sHelper.download(item.RqdAttach, 1).then((r) => {
                this.$fun.download(r.data, item.RqdAttach);
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
