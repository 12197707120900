<template>
<div>

    <v-row justify="center" v-if="!showUploadEdit">

        <v-card elevation="0" style="margin-top: 2px;border-radius:10px" class="mt-2">
            <div class="card-body">

                <h4 class="card-title" style="font-size: 16px;">MIS ORDENES</h4>
                <v-divider></v-divider>
                <div class="px-0 pt-0 text-left  px-lg-0">
                    <p class="mb-5 text-sm">
                        <a href="https://youtu.be/F98BUbCa2Iw" style="text-decoration:underline" target="_blank" class="font-weight-bold">Cómo subir mis comprobantes de pago!</a>
                    </p>
                </div>
                <v-row>
                    <vc-crud ref="orderprovider" no-full :config="config" :filter="filter" title="Ordenes de Compra/Servicio" searchInput searchBottom @rowSelected="rowSelected($event)">

                        <template v-slot:OrdStatusName="{ row }">
                            <v-chip x-small dark :color="row.OrdStatusColor">
                                {{ row.OrdStatusName}}
                            </v-chip>
                        </template>

                        <template v-slot:TypeOrderName="{ row }">
                            <v-icon :color="row.TypeOrder == 1 ? 'red' : 'green'" style="font-size:12px;">
                                {{row.TypeOrder == 1 ? 'fas fa-car': 'fas fa-street-view'}}

                            </v-icon>
                            <label class="ml-2">{{row.TypeOrderName}}</label>
                        </template>

                        <template v-slot:OrdStatusReceptionName="{ row }">
                            <label>{{ row.OrdStatusReceptionName}}</label>
                        </template>

                        <template v-slot:attach="{ row }">
                            <v-chip x-small dark :color="row.Files == 1 ? 'success' : 'error'">
                                {{row.Files == 1 ? 'Si' : 'No'}}
                            </v-chip>

                        </template>
                        <template v-slot:RegisterComp="{ row }">
                            <v-chip x-small dark :color="row.RegisterComp == 1 ? 'success' : 'error'">
                                {{row.RegisterComp == 1 ? 'Registrado' : 'Sin registrar'}}
                            </v-chip>

                        </template>

                        <template v-slot:upload="{ row }">
                            <v-btn v-if="row.OrdStatus !== 3 && row.OrdStatus !== 6" title="Subir Files" elevation="0" style="text-transform:capitalize" small icon @click="showAttach(row)">
                                <v-icon style="font-size: 16px;color:green">fa-solid fa-file-arrow-up</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:view="{ row }">
                            <v-btn title="Subir Files" elevation="0" style="text-transform:capitalize" small icon @click="view(row)">
                                <v-icon style="font-size: 16px;color:brown">far fa-eye</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:IsAttachVoucher="{ row }">
                            <v-chip :color="row.IsAttachVoucher > 0 ? 'success':''" x-small>{{row.IsAttachVoucher > 0 ? 'Si' : 'No'}}</v-chip>
                        </template>

                        <template v-slot:EvtPromEvaluate="{ row }">
                            <rate v-if="row.EvtPromEvaluate>0" :readonly="true" :length="4" :value="row.EvtPromEvaluate" :ratedesc="['Malo', 'Regular', 'Bueno', 'Muy Bueno']" />
                            <div v-else></div>
                        </template>

                        <template v-slot:filter>
                            <v-container>
                                <v-row justify="center">

                                    <v-col lg="4" md="6" cols="6" class="pt-0 pb-0">
                                        <vc-date label="Fecha Inicio" clearable v-model="filter.BeginDate" />
                                    </v-col>
                                    <v-col lg="4" md="6" cols="6" class="pt-0 pb-0">
                                        <vc-date label="Fecha Fin" clearable v-model="filter.EndDate" />
                                    </v-col>
                                    <v-col lg="3" md="4" cols="12" class="pt-0">
                                        <vc-select-definition label="Estados" :dgr="1011" :def="1026" v-model="filter.OrdStatus" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </vc-crud>
                </v-row>
            </div>
        </v-card>

    </v-row>

    <v-dialog class="elevation-0" v-if="showUploadEdit" v-model="showUploadEdit" persistent transition="dialog-bottom-transition" max-width="80%">
        <sup-upload-attach :value="item.OrdID" @close="showUploadEdit=false;refresh()" />
    </v-dialog>

    <v-dialog v-if="dialogOrder" v-model="dialogOrder" persistent>
        <lgs-order-purcharse-edit @close="dialogOrder=false" :value="item.OrdID" :order="{}"></lgs-order-purcharse-edit>
    </v-dialog>

</div>
</template>

<script>
//Services
import _sOrderPurcharse from "@/services/Logistics/LgsOrderPurcharseService";
import _sGenSupplier from "@/services/General/GenSupplierService.js";
//Components
import SupUploadAttach from "./SupUploadAttach";
import LgsOrderPurcharseEdit from "@/views/Logistics/LgsOrderPurcharse/LgsOrderPurcharseEdit.vue";

import 'vue-rate/dist/vue-rate.css';
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        SupUploadAttach,
        LgsOrderPurcharseEdit

    },
    props: {},
    data() {

        return {
            showUploadEdit: false,
            dialogOrder: false,
            selected: [],
            filter: {
                SupDocumentNumber: this.$fun.getUserName()
            },
            config: {
                model: {
                    OrdID: "ID",
                    OrdDate: "date",
                    OrdDocumentNumber: "string",
                    OrdStatusName: "string",
                    OrdStatus: "",
                    OrdStatusReceptionName: "",
                    TypeOrderName: "",
                    upload: "",
                    attach: "",
                    RegisterComp: "",
                    view: "",
                    IsAttachVoucher: "",
                    EvtPromEvaluate: ""

                },
                service: _sOrderPurcharse,
                headers: [{
                        text: "Subir Comp.",
                        value: "upload",
                        sortable: false,
                        align: "center",
                        width: 70
                    },

                    {
                        text: "Ver",
                        value: "view",
                        sortable: false,
                        align: "center",
                        width: 70
                    },

                    {
                        text: "Tipo Orden",
                        value: "TypeOrderName",
                        width: 120
                    },
                    {
                        text: "N° Doc.",
                        value: "OrdDocumentNumber"
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate"
                    },
                    {
                        text: "Ruc",
                        value: "SupDocumentNumber"
                    },
                    /* {
                        text: "Proveedor",
                        value: "SupName"
                    }, */
                    {
                        text: "Total (Inc. IGV)",
                        value: "OrdTotal"
                    },
                    {
                        text: "Registrado Por",
                        value: "UsrName"
                    },
                    {
                        text: "Estado Proceso",
                        value: "OrdStatusName"
                    },
                    {
                        text: "Estado Recep.",
                        value: "OrdStatusReceptionName"
                    },
                    {
                        text: "Comp. Pagos",
                        value: "IsAttachVoucher"
                    },
                    {
                        text: "Evaluación",
                        value: "EvtPromEvaluate",
                        align: "center",
                        width: 200
                    },

                ]
            },
            item: {}
        };
    },

    mounted() {
        this.initializeProvider()
    },

    created() {

    },

    computed: {

    },

    methods: {
        refresh() {
            console.log(this.$refs.orderprovider);
            //this.$refs.orderprovider.refresh()
        },

        initializeProvider() {
            let supplier = {
                ruc: this.$fun.getUserName()
            }
            _sGenSupplier
                .searchbyruc(supplier)
                .then(resp => {
                        if (resp.status == 200) {
                            this.provider = resp.data.Result
                            console.log("provider", resp.data.Result);
                        }
                    },
                    (e) => {})
        },
        rowSelected(item) {
            if (item.length > 0) {
                this.selected = item
            }
        },

        showAttach(item) {

            if (this.provider.SupAttachDJ == null) {
                this.$fun.alert("Debe adjuntar Declaración Jurada.", "warning", false, "center")
                return
            }

            this.item = item
            this.showUploadEdit = true

        },
        view(item) {
            this.item = item
            this.dialogOrder = true
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
