<template>
<div>
    <v-row class="mb-3" style="margin:auto">
        <!-- <v-col cols="12"> -->
        <v-card outlined elevation="0" height="auto">
            <vc-header :title="title" close @close="$emit('close')" save @save="save">
            </vc-header>

            <!-- <v-row style="margin: auto">
                    <v-col v-for="(item, index) in itemsSupport" :key="index" class="s-col-form">
                        <label style="margin-bottom : 7px;"><b>{{index}}</b></label>
                        <v-checkbox v-for="(it, ind) in item.details" :key="ind" :label="it" color="red" :value="it" v-model="supportSelected" hide-details style="margin-top:-10px">
                        </v-checkbox>
                    </v-col>
                </v-row> -->
            <v-row style="margin:auto">

                <v-col lg="3">
                    <label>Obligatorios</label>
                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreCompPayment" label="Comp. de Pago" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreXml" label="XML" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreCdr" label="CDR" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreOcos" label="OC/OS" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -10px" v-model="SupportRequired.SreQuotation" label="Cotización" color="red" hide-details></v-checkbox>
                </v-col>
                <v-col lg="3">
                    <label>Obra</label>
                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreContracts" label="Contratos" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreAddenda" label="Adendas" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreValuations" label="Valorizaciones" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreRecordConformity" label="Acta de Conformidad" color="red" hide-details></v-checkbox>
                </v-col>

                <v-col lg="2">
                    <label>Servicios</label>
                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreContracts_1" label="Contratos" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreAddenda_1" label="Adendas" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreReports" label="Informes" color="red" hide-details></v-checkbox>
                </v-col>

                <v-col lg="2">
                    <label>Bienes y Productos</label>
                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreGuideRemission" label="Guia de Remision" color="red" hide-details></v-checkbox>
                </v-col>

                <v-col lg="2">
                    <label>Demas Sustentos</label>
                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SrePhotos" label="Fotos" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreLiquidations" label="Liquidaciones" color="red" hide-details></v-checkbox>
                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreRecordDelivery" label="Acta de entrega" color="red" hide-details></v-checkbox>
                </v-col>
            </v-row>
        </v-card>
        <!-- </v-col> -->

    </v-row>
</div>
</template>

<script>
//Services
/* import _supportOrderService from "@/services/General/GenRequiredSupportService"; */
import _sOrderPurchase from "@/services/Logistics/LgsOrderPurcharseService";

export default {
    name: '',
    props: {
        title: {
            type: String,
            default: "Sustentos Requeridos - Adelantos"
        },
        value: {
            default: 0,
            type: Number,
        },

    },

    data() {
        return {
            /* supportOriginal: [],
            itemsSupport: [],
            supportSelected: [] */
            SupportRequired: {
                SreCompPayment: false,
                SreXml: false,
                SreCdr: false,
                SreOcos: false,
                SreQuotation: false,
                SreContracts: false,
                SreAddenda: false,
                SreValuations: false,
                SreRecordConformity: false,
                SreContracts_1: false,
                SreAddenda_1: false,
                SreReports: false,
                SreGuideRemission: false,
                SrePhotos: false,
                SreLiquidations: false,
                SreRecordDelivery: false,
                OrdID: this.value
            },
        };
    },

    created() {
        console.log("PK", this.value);
        if (this.value > 0) {
            this.initialize()
        }
    },

    watch: {

    },

    methods: {

        initialize() {
            _sOrderPurchase
                .byID({
                    OrdID: this.value,
                })
                .then((r) => {
                    if(r.data.Result.itemsSupportAdavacement !== null){
                        this.SupportRequired = r.data.Result.itemsSupportAdavacement
                    }
                    
                });

        },
        save() {
            _sOrderPurchase.saveAdvacement(this.SupportRequired).then(resp => {

                if (resp.data.Result.Data > 0) {
                    this.$fun.sweetAlert("Grabado correctamente.","success")
                    this.$emit('close')
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
