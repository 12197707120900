let children = [];

children.push({
    path: "/contabilidad/mailbox",
    name: "CON_MAILBOX",
    component: () =>
        import ("../views/Accounting/ConMailBox.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Mailbox", href: "/contabilidad/mailbox" },
        ],
    },
});


children.push({
    path: "/contabilidad/periodos",
    name: "CON_PERIOD",
    component: () =>
        import ("../views/Accounting/ConPeriod.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Periodos", href: "/contabilidad/periodos" },
        ],
    },
});

children.push({
    path: "/contabilidad/comprobantes",
    name: "CON_VOUCHER",
    component: () =>
        import ("../views/Accounting/ConVoucher.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Comprobantes", href: "/contabilidad/comprobantes" },
        ],
    },
});

children.push({
    path: "/contabilidad/creardiario",
    name: "CON_DIARIO",
    component: () =>
        import ("../views/Accounting/ConDiario.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            { text: "Diario", href: "/contabilidad/creardiario" },
        ],
    },
});

export { children };