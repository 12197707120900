<template>
<div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isOnline: true, // Inicialmente asumimos que está en línea
        };
    },
    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.updateOnlineStatus();
    },
    methods: {
        updateOnlineStatus() {
            this.isOnline = window.navigator.onLine;

            if(!this.isOnline){
                this.$fun.alertFull("Sin conexión a internet.", "error");
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
};
</script>

<style lang="scss" scoped>

</style>
