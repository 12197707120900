import Service from "../Service";
import ServiceReport from "../ServiceReport";

const resource = "LgsOrderPurcharseAttended/";
const resourceReport = "orderpurchase/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: {},
        });
    },


    save(obj) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },

    anulated(obj) {
        return Service.put(resource + "anulated", obj, {
            params: {},
        });
    },

    editDate(obj) {
        return Service.put(resource + "editdates", obj, {
            params: {},
        });
    },

    generatedConformity(OttID, OrdID, OttDocumentNumber) {
        return Service.get(resource + "generatedconformity", {
            params: {
                OttID:OttID,
                OrdID: OrdID,
                OttDocumentNumber: OttDocumentNumber
            },
        });
    },

    /* update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    }, */

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: {},
        });
    },

    printPDF(OrdID,OttID, UsrCreateID) {
        return ServiceReport.get(resourceReport + "pdforderpurcharseattended",  {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                OrdID: OrdID,
                OttID: OttID,
                UsrCreateID:UsrCreateID
            },
        });
    },


};