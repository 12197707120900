<template>
<div>
    <v-card class="mt-2">
        <vc-header style="font-family: Calibri" title="Registro de Cotización" dark close @close="$emit('close')">
            <v-divider vertical></v-divider>
        </vc-header>

        <v-card outlined class="mt-0 pb-4" style="max-height: 650px;  overflow-y: auto;">
            <v-col>
                <form id="formsuppliercotization">
                    <v-row>

                        <v-col cols="12">
                            <vc-header-title title="Datos de Contacto" />

                            <!--  <v-col class="pr-0 pl-0"> -->
                            <v-row>
                                <v-col cols="12" lg="5" md="5" class="s-col-form">
                                    <vc-text id="SupConName" autofocus label="Contacto" disabled v-model="orderLocal.SupConName"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="2" md="2" class="s-col-form">
                                    <vc-text id="SupTelephone" label="Celular" number :min="0" disabled v-model="orderLocal.SupTelephone"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="2" md="2" class="s-col-form">
                                    <vc-text id="SupEmail" label="Correo" v-model="orderLocal.SupEmail" disabled></vc-text>
                                </v-col>
                                <v-col cols="12" lg="3" md="3" class="s-col-form">
                                    <vc-date id="CttExpirationOffer" disabled label="Oferta válida hasta el" v-model="orderLocal.CttExpirationOffer"></vc-date>
                                </v-col>
                            </v-row>

                            <!-- </v-col> -->
                        </v-col>

                        <v-col lg="5" cols="12">
                            <vc-header-title title="Datos Generales">
                                <template v-slot:options>
                                    <h6 class="bounce" v-if="orderLocal.CttRequiredVisit" style="color:red">Cotización requiere de visita a campo</h6>
                                </template>
                            </vc-header-title>

                            <v-col class="pr-0 pl-0">
                                <!-- <form id="formcotization"> -->
                                <v-row>

                                    <!-- <v-card outlined width="100%" style="margin-top:0px"> -->
                                    <v-row style="margin:auto" class="pb-0 pt-0">

                                        <v-col cols="12">

                                            <ul class="ml-6">
                                                <li>
                                                    <b><label>Fecha Solicitud:</label> {{$moment(orderLocal.CttDate).format($const.FormatDateDB)}}</b>
                                                </li>
                                                <li>
                                                    <b><label>Fecha Entrega Cotización:</label> {{$moment(orderLocal.NeedDateCot).format($const.FormatDateDB)}}</b>
                                                </li>
                                                <li v-if="orderLocal.CttRequiredVisit">
                                                    <b><label>Fecha y Hora visita:</label> {{$moment(orderLocal.CttDateRequiredVisit).format($const.FormatDateDB)}}{{' '}}{{orderLocal.CttHourRequiredVisit}}</b>
                                                </li>
                                                <li v-if="orderLocal.CttRequiredVisit">
                                                    <b><label>Preguntar por:</label> {{orderLocal.NtpFullName}}</b>
                                                </li>
                                                <li>
                                                    <b><label>Lugar de entrega:</label> {{''}}</b>
                                                </li>
                                            </ul>
                                        </v-col>

                                    </v-row>
                                    <!-- </v-card> -->
                                </v-row>

                                <!-- </form> -->
                            </v-col>

                        </v-col>
                        <v-col lg="7" cols="12">
                            <vc-header-title title="Condiciones de Pago" />
                            <!-- <v-card outlined width="100%" style="margin-top:0px" class="pb-7"> -->
                            <v-row style="margin:auto">

                                <v-col cols="12" lg="4" class="s-col-form">
                                    <!-- <label>
                                        <h6>Forma de Pago</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <!-- <v-select label="Forma de Pago" id="TypePayment" :items="typePayment" item-text="TypePaymentName" item-value="CppID" v-model="selectedPayment"></v-select> -->
                                    <vc-text label="Forma de Pago" disabled v-model="orderLocal.TypePaymentName"></vc-text>
                                </v-col>

                                <v-col cols="6" lg="4" class="s-col-form">
                                    <!-- <label>
                                        <h6>Condición de Pago</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <!-- <v-select label="Condición de Pago" id="ConID" :items="paymentCondition" item-text="ConDescription" item-value="ConID" v-model="orderLocal.ConID"></v-select> -->
                                    <vc-text label="Condición de Pago" disabled v-model="orderLocal.ConDescription"></vc-text>
                                </v-col>
                                <v-col cols="6" lg="4" class="s-col-form">
                                    <!--  <label>
                                        <h6>Días Credito</h6>
                                    </label>
                                    <v-divider class="mt-0" /> -->
                                    <!-- <v-select label="Días Credito" id="DayID" :items="dayCredit" item-text="DayDescription" item-value="DayID" v-model="orderLocal.DayID"></v-select> -->
                                    <vc-text label="Días Credito" disabled v-model="orderLocal.DayDescription"></vc-text>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="6" lg="4" class="s-col-form">
                                    <vc-text :outlined="false" id="OrdDeliveryDay" disabled number :min="1" label="Tiempo de Entrega(días)" v-model="orderLocal.OrdDeliveryDay"></vc-text>
                                </v-col>

                                <v-col cols="6" lg="4" class="s-col-form">
                                    <vc-text :outlined="false" id="OrdWarrantyDay" disabled number :min="0" label="Garantia(días)" v-model="orderLocal.OrdWarrantyDay"></vc-text>
                                </v-col>
                                <v-col cols="12" lg="4" class="s-col-form">
                                    <vc-select-definition :outlined="false" disabled id="TypeCurrency" label="Moneda" :def="1020" v-model="orderLocal.TypeCurrency"></vc-select-definition>
                                </v-col>
                            </v-row>
                            <!-- </v-card> -->

                        </v-col>

                        <v-divider vertical></v-divider>
                        <v-col lg="12" cols="12">
                            <vc-header-title title="Items Requeridos" />
                            <v-row>
                                <v-col>
                                    <v-data-table disable-sort class="table-bordered" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="CtdID" :height="'auto'" hide-default-footer>

                                    </v-data-table>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12">
                            <v-card elevation="0" height="auto">
                                <!-- <vc-header-title /> -->

                                <v-row style="margin: auto">
                                    <v-col cols="12" lg="8" md="8" class="s-col-form pb-3">
                                        <!-- <v-textarea auto-grow ref="OrdObservation" label="Observaciones Generales" v-model="orderLocal.OrdObservation" /> -->
                                        <quill-editor id="ReqObservation" v-model="orderLocal.OrdObservation" ref="myQuillEditor" :options="editorOption"></quill-editor>
                                    </v-col>
                                    <v-col cols="12" lg="4" md="4" class="s-col-form mt-2">
                                        <div>
                                            <vc-select-definition id="tax" label="Impuesto" :outlined="false" :def="1027" disabled v-model="orderLocal.TypePercentage" />
                                        </div>

                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>V. NETO</b></v-label>
                                            </v-col>
                                            <v-col lg="6" cols="6" class="s-col-form">
                                                <h5 class="text-right">
                                                    <h5 class="text-right">
                                                        {{
                            orderLocal.OrdTotalNet >= 1000
                              ? orderLocal.OrdTotalNet.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotalNet > 0 ? orderLocal.OrdTotalNet.toFixed(2) : 0
                          }}
                                                    </h5>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row v-if="orderLocal.CttCheckGTO">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>GTO GENERALES</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">

                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdGeneralgto"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="orderLocal.CttCheckUtilities">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>UTILIDADES</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">

                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdUtilities"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="orderLocal.CttCheckDiscount">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>DESCUENTO</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">
                                                <h5 style="margin-top:-20px" class="text-right">
                                                    <vc-text decimal v-model="orderLocal.OrdDiscount"></vc-text>
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;margin-bottom:-17px">
                                            <v-col lg="6" class="s-col-form">
                                                <v-label><b>SUB TOTAL</b></v-label>
                                            </v-col>
                                            <v-col lg="6" class="s-col-form">
                                                <h5 class="text-right">
                                                    {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              :  orderLocal.OrdSubTotal > 0 ? orderLocal.OrdSubTotal.toFixed(2) : 0
                          }}
                                                </h5>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col class="s-col-form">
                                                <v-label><b>IMPUESTO{{ " (" + orderLocal.OrdTaxPercentage + "%)" }}</b></v-label>
                                            </v-col>
                                            <v-col class="s-col-form">
                                                <h5 class="text-right">

                                                    {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount > 0 ? orderLocal.OrdTaxAmount.toFixed(2): 0
                          }}
                                                </h5>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-4 mb-4"></v-divider>
                                        <v-row style="margin-top:-15px;">
                                            <v-col class="s-col-form">
                                                <v-label><b>TOTAL</b></v-label>
                                            </v-col>
                                            <v-col class="s-col-form">
                                                <h4 class="text-right">
                                                    {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal > 0 ? orderLocal.OrdTotal.toFixed(2) : 0

                          }}
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card outlined width="100%" style="margin-top: 10px">
                                <v-card-title style="
                      margin-top: -10px;
                      margin-bottom: -10px;
                      font-family: Calibri;
                    ">
                                    <v-col cols="12">
                                        Cotización
                                        <br>
                                        <i v-if="orderLocal.Attach1!==null" class="fa-solid fa-download mr-2" style="color:green" @click="downloadFile(orderLocal.Attach1)"></i>
                                        <div>{{orderLocal.Attach1}}</div>
                                        <!-- <br />
                                        <b>(El nombre del archivo debe tener la siguiente
                                            estructura)</b>
                                        <b style="color: red">Ejem: NroCotización-Ruc-NombreArchivo</b>
                                        <br />
                                        <b>{{orderLocal.CttDocumentNumber+"-"+provider.SupDocumentNumber}}</b> -->
                                        <!-- <v-file-input :rules="rules" show-size counter truncate-length="100" ref="myidcotizacion" :accept="'.pdf,.xlsx,.txt,.docx'" label="Adjuntar Cotizaciòn" v-model="Attach1" /> -->
                                    </v-col>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-card>

        <vc-footer />
    </v-card>

</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/LgsCotizationService";
import _sHelper from "@/services/HelperService.js";

//Components
import {
    quillEditor
} from "vue-quill-editor";

export default {
    components: {
        quillEditor

    },

    props: {
        value: {
            type: Number,
            default: 0
        },
        provider: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {

            orderLocal: {
                SupTelephone: ""
            },
            headersSAP: [{
                    text: "Tipo",
                    value: "TypeCotization",
                    width: 100,
                },

                {
                    text: "Código Art.",
                    value: "ArtCode",
                    width: 100,
                },
                {
                    text: "Descripción",
                    value: "CtdDescription",

                },
                {
                    text: "Cant",
                    value: "CtdQuantity",
                    width: 120,
                },

                {
                    text: "Und",
                    value: "CtdUnitMeasurement",
                    width: 100,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                    width: 240,
                },
                {
                    text: "Cant Stock.",
                    value: "OddQuantity",
                    width: 100,
                },
                {
                    text: "P. Uni. (Sin IGV)",
                    value: "OddUnitPrice",
                    width: 100,
                },
                {
                    text: "V. Neto",
                    value: "OddAmount",
                    width: 80,
                },
                /* {
                    text: "Comentario",
                    value: "EtdObservation",
                    width: 300,
                }, */
            ],
            itemsSAP: [],
            selectedSAP: [],
            editorOption: {
                // some quill options
            },
        };
    },

    created() {

        this.initialize();

    },

    mounted() {},

    watch: {

    },

    methods: {

        initialize() {
            _sCotization
                .byID({
                    CttID: this.value,
                })
                .then((resp) => {
                    if (resp.status == 200) {
                        var obj = resp.data.Result.itemsParticipant.find(x => x.CtpRuc == this.$fun.getUserName())

                        this.orderLocal = resp.data.Result.itemsParticipant.find(x => x.CtpRuc == this.$fun.getUserName()).itemProvider
                        this.itemsSAP = this.orderLocal.itemsDetails

                    }
                });
        },

        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        downloadFile(url) {
            if (url == null) return;
            _sHelper.download(url, 3).then((r) => {
                this.$fun.download(r.data, url);
            });
        }

    },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 1s infinite;
}
</style>
