<template>
<div>
    <v-row>
        <vc-modal-dialog title="Detalle de Catálogos" save close :dialog="dialog" :data="item" :config="config" @save="save($event, $event)" @close="dialog=false" xs>

            <template slot-scope="props">
                <form id="definitiondetail">
                    <v-row class="mt-3 mb-2">
                        <v-col cols="12" class="s-col-form">
                            <vc-text id="DedDescription" v-model="props.item.DedDescription" autofocus label="Descripción Detalle" />
                        </v-col>
                       
                        <v-col cols="12" class="s-col-form">
                            <vc-textarea v-model="props.item.DedAbbreviation" label="Abreviatura" />
                        </v-col>
                         <v-col cols="3" class="s-col-form">
                            <vc-text id="DedValue" number v-model="props.item.DedValue" label="Valor" />
                        </v-col>

                        <v-col cols="9" class="s-col-form">
                            <vc-text v-model="props.item.DedHelper" label="Utilitario" />
                        </v-col>
                    </v-row>

                </form>

            </template>
        </vc-modal-dialog>
        <v-col cols="12" lg="12" md="12">
            <vc-crud no-full restore height="350" ref="crudDefinitiondetail" title="Detalle de Catálogos" :config="this.config" :filter="filter" @add="dialog = true;item={}" @edit="edit($event)" add :edit="selected.length > 0" :remove="selected.length > 0" searchInput @rowSelected="rowSelected($event)">
                <template v-slot:SecStatus="{ row }">
                    <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
                </template>
            </vc-crud>
        </v-col>
    </v-row>
</div>
</template>

<script>
import _sDefinitionDetail from "@/services/General/GenDefinitionDetailService";
import _validaERP from '@/plugins/validaERP';

export default {
    components: {},
    props: {
        defID: 0,
    },
    data() {
        return {
            dialog: false,
            config: {
                title: "Definiciones Detalles",
                service: _sDefinitionDetail,
                model: {
                    DedID: "ID",
                    DefID: "int",
                    DedDescription: "string",
                    DedAbbreviation: "string",
                    DedHelper: "string",
                    DedValue: "int",

                    SecStatus: "string",
                },
                headers: [{
                        text: "ID",
                        value: "DedID",
                        width: "5%",
                        align: "end",
                        sorteable: true,
                    },
                    {
                        text: "Definicion",
                        value: "DedDescription",
                        width: "55%",
                        sorteable: true,
                    },
                    {
                        text: "Abreviatura",
                        value: "DedAbbreviation",
                        width: "15%",
                        sorteable: false,
                    },
                    {
                        text: "Helper",
                        value: "DedHelper",
                        width: "15%",
                        sorteable: false,
                    },
                    {
                        text: "Valor",
                        value: "DedValue",
                        width: "5%",
                        align: "center",
                        sorteable: true,
                    },
                    {
                        text: "Estado",
                        value: "SecStatus",
                        width: "5%",
                        align: "center",
                        sorteable: false,
                    },
                ],
            },
            item: {},
            selected:[]
        };
    },
    computed: {
        filter() {
            return {
                DefID: this.defID,
                SecStatus: 0,
                DgrID: 0
            };
        },
    },
    watch: {},

    methods: {
        rowSelected(row){
            this.selected=row
        },
        edit(item) {
            this.item = item
            console.log("itemitemitem", this.item)
            this.dialog = true
        },
        save(item) {
            //Validamos Campos
            var resp = _validaERP.valida({
                form: '#definitiondetail',
                items: {
                    DedDescription: {
                        required: true,
                        minlength: 2,
                        value: item.DedDescription,
                    },
                    /* DedValue: {
                        required: true,
                        min: 1,
                        value: item.DedValue,
                    }, */
                }
            })

            

            if (resp) {
                item.DefID = this.defID;
                item.SecStatus = 1
                item.UsrCreateID = this.$fun.getUserID()

                console.log("save", item)

                item.save().then(resp => {
                    if (resp.status == 200) {
                        this.$refs.crudDefinitiondetail.refresh()
                    }
                })
            }
        },

    },
};
</script>

<style lang="scss" scoped>

</style>
