import Service from "../Service";

const resource = "LgsOrderPurcharseVoucher/";

export default {

    pagination(parameters) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },
    
    save(obj) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },

    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },

    accept(obj) {
        return Service.post(resource + "accept", obj, {
            params: {},
        });
    },

    reception(obj) {
        return Service.post(resource + "reception", obj, {
            params: {},
        });
    },

    anulated(obj) {
        return Service.post(resource + "anulated", obj, {
            params: {},
        });
    },

    enabledAttach(obj) {
        return Service.post(resource + "enabledattach", obj, {
            params: {},
        });
    },

    isObservation(obj) {
        return Service.post(resource + "isobservation", obj, {
            params: {},
        });
    },


    saveComment(obj) {
        return Service.post(resource + "savecomment", obj, {
            params: {},
        });
    },

    byID(VouID) {
        return Service.get(resource + "byid", {
            params: {VouID: VouID},
        });
    },

    byOrd(OrdID) {
        return Service.get(resource + "byord", {
            params: {OrdID: OrdID},
        });
    },

    list(filter) {
        return Service.post(resource + "list", filter, {
            params: {},
        });
    },


};