import { render, staticRenderFns } from "./LgsContractLegalView.vue?vue&type=template&id=43191166&scoped=true&"
import script from "./LgsContractLegalView.vue?vue&type=script&lang=js&"
export * from "./LgsContractLegalView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43191166",
  null
  
)

export default component.exports