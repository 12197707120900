import Service from "../Service";

const resource = "CobAdvertisement/";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },
    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },
    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    getAdvertisement(LexID) {
        return Service.get(resource + "getadvertisement", {
            params: {LexID : LexID},
        });
    },
};