<template>
<div>
    <v-footer dense flat class="body-2 font-weight-bold" :color="'#f5f5f5'">
        <v-spacer></v-spacer>
        <v-btn elevation="0" v-if="accept" class="capitalize" text color="info" @click="$emit('accept')">
            Aceptar
        </v-btn>
        <v-btn elevation="0" v-if="save" class="capitalize" text color="info" @click="$emit('save')">
            Guardar
        </v-btn>
        <v-divider vertical></v-divider>
        <slot name="options"> </slot>
        <v-divider vertical></v-divider>
        <v-btn v-if="close" elevation="0" class="capitalize" text color="error" @click="$emit('close')">
            Cerrar
        </v-btn>

    </v-footer>
</div>
</template>

<script>
export default {
    name: "vcFooter",
    props: {
        accept: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped></style>
