import { render, staticRenderFns } from "./PagPayment.vue?vue&type=template&id=94e65ad2&scoped=true&"
import script from "./PagPayment.vue?vue&type=script&lang=js&"
export * from "./PagPayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94e65ad2",
  null
  
)

export default component.exports