import Service from "../Service";
import ServiceReport from "../ServiceReport";

const resource = "LgsCotization/";
const resourceReport = "helper/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj) {
        return Service.post(resource + "save", obj, {
            params: { },
        });
    },
    /* update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    }, */

    anulated(obj) {
        return Service.put(resource + "anulated", obj, {
            params: {},
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byId", parameters, {
            params: { requestID: requestID },
        });
    },

    saveParticipant(obj) {
        return Service.post(resource + "saveparticipant", obj, {
            params: { },
        });
    },

    updateParticipant(obj) {
        return Service.put(resource + "updateparticipant", obj, {
            params: { },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: {},
        });
    },


    //Supplier
    
    saveprovider(obj) {
        return Service.post("LgsCotizationProvider/" + "save", obj, {
            params: { },
        });
    },

    itemsproviderByID(obj, requestID) {
        return Service.post(resource + "providerbyid", obj, {
            params: { requestID: requestID },
        });
    },

    savewinner(obj) {
        return Service.post(resource + "savewinner", obj, {
            params: { },
        });
    },

    createversion(obj) {
        return Service.post(resource + "createversion", obj, {
            params: {  },
        });
    },

    sendInvitation(obj) {
        return Service.post(resource + "sendinvitation", obj, {
            params: {  },
        });
    },




    //Circuitos
    /* sendCircuit(obj, requestID, AccID) {
        return Service.post(resource + "sendcircuit", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    }, */

 /*    datapdf(obj) {
        return Service.post(resource + "datapdf", obj, {
            params: {},
        });
    },

    printPDF(obj) {
        return ServiceReport.post(resourceReport + "pdfcotization", obj, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },

    dataexcel(obj) {
        return Service.post(resource + "dataexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    }, */
};