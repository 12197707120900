<template>
<div>
    <v-dialog v-model="showHour" v-if="showHour" max-width="500" persistent>

        <div class="main-card mb-3 card">

            <div class="card-header">
                <!--  <i class=""></i> -->
                <i class="header-icon fa-regular fa-solid fa-clock icon-gradient bg-plum-plate"></i>Horario de Atención
            </div>
            <!-- <v-card style="max-height: 450px;  overflow-y: auto;"> -->
            <v-img cover class="white--text align-end" height="200px" src="./../../assets/images/cobranzas/img02.png">
            </v-img>
            <div class="card-body ml-4">
                <b style="color:#03296a">Hola estimado {{$fun.getUserInfo().UsrFullName == null ? "ERROR AL CARGAR DATOS": $fun.getUserInfo().UsrFullName}}</b>
                <br>
                <p style="color:#3d5783">Le informamos que el horario de atención es de:</p>

                <div class="card no-shadow rm-border bg-transparent widget-chart text-center" style="border-radius: 20px;margin-top:-40px">

                    <div class="widget-chart-content ml-3 mt-7">
                        <div class="widget-numbers" style="font-size:35px;color: #faa919;">
                            <span class="mr-4 mt-4" style="font-size:40px;">{{HourInital}} {{amPm1}} - {{HourEnd}} {{amPm2}}
                                <!-- <i class="v-icon material-icons theme--light text-success text--darken-2 bounce iconhome" style="font-size: 34px;">payment</i> -->
                            </span><br><span class="mr-4">Lunes a Viernes </span>
                        </div>
                    </div>
                    <v-divider />
                </div>

            </div>
            <div class="main-card mb-0 card">
                <div class="card-body">
                    <i class="fa-solid fa-location-dot"></i>
                    <span style="font-size:10px;color:#3d5783" class="ml-1">ÁREA DE CONTABILIDAD</span>

                </div>

            </div>
            <!-- </v-card> -->

            <vc-footer close @close="$router.go(0)"></vc-footer>
        </div>
    </v-dialog>

</div>
</template>

<script>
//Services
import _sHour from "@/services/Accounting/ConServiceHourService"

export default {
    components: {

    },
    name: '',
    props: {},

    data() {
        return {
            horaFueraDeHorario: false,
            showHour: false,
            item: {

            },
            HourInital: "",
            HourEnd: "",
            amPm1: "",
            amPm2: "",
            usrExtern:false
        };
    },

    created() {
        this.usrExtern = JSON.parse(localStorage.getItem('UsrExtern'))
        if(this.usrExtern){
            this.validHour()
        }
        
    },

    methods: {
        validHour() {

            _sHour.byOne().then(resp => {
                this.item = resp.data.Result
                this.HourInital = this.item.HouHourInitial.split(":")[0] + ":" + this.item.HouHourInitial.split(":")[1]
                this.HourEnd = this.item.HouHourEnd.split(":")[0] + ":" + this.item.HouHourEnd.split(":")[1]

                const [hour] = this.item.HouHourInitial.split(":");
                const [hour2] = this.item.HouHourEnd.split(":");

                console.log("hourhourhour",hour,hour2);
                this.amPm1 = parseInt(hour) >= 12 ? "PM" : "AM";
                this.amPm2 = parseInt(hour2) >= 12 ? "PM" : "AM";

                const horaActual = new Date(this.item.HouDateServer);
                const diaSemana = horaActual.getDay(); // 0 para domingo, 1 para lunes, 2 para martes, etc.
                const hora = horaActual.getHours();
                const hourInitial = this.item.HouHourInitial.split(":")[0]
                const hourEnd = this.item.HouHourEnd.split(":")[0]

                const esFinDeSemana = diaSemana === 0 || diaSemana === 6;
                const estaFueraDeHorario = hora < hourInitial || hora >= hourEnd;

                if (esFinDeSemana || estaFueraDeHorario) {
                    this.showHour = true;
                } else {
                    this.showHour = false;
                }

            })

        },
    },
};
</script>

<style lang="scss" scoped>

</style>
