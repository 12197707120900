<template>
<div>

    <v-card elevation="0" class="pl-2 pb-3 pr-2 mr-0 mt-1" style="border-radius: 0px;">
        <vc-header-title title="Detalle" class="pt-3">
            <template v-slot:options>
                <v-btn v-if="requirement.ReqStatus == 1 || requirement.ReqStatus == 4" class="ml-1 mr-1" title="Asociar a Circuito" elevation="0" style="text-transform:capitalize" small @click="dialogAnulated=true">
                    <v-icon style="font-size: 16px;margin-right:5px;color:">fa-solid fa-ban</v-icon>Anular
                </v-btn>
            </template>
        </vc-header-title>
        <div>
            <v-row class="mb-2 pl-1 pr-1 pt-2" style="">

                <v-data-table  hide-default-footer :items-per-page="-1" class="" v-if="value > 0"  dense :items="itemsSAP" :headers="headersSAP" item-key="OddID" v-model="selectedSAP">
                   

                </v-data-table>
            </v-row>

        </div>
    </v-card>

</div>
</template>

<script>
//Services
import _sOrderPurcharse from "@/services/Logistics/LgsOrderPurcharseService";

export default {
    name: '',
    components: {

    },

    props: {
        value: 0
    },

    data() {
        return {

            requirement: {},
            selectedSAP: [],
            headersSAP: [{
                    text: "Cod. Art.",
                    value: "ArtCode",
                    sortable: false,
                    width: 80,
                },
                {
                    text: "Descripción",
                    value: "OddDescription",
                    sortable: false,
                    width: 340,
                },
                {
                    text: "Prc. U.(Sin IGV)",
                    value: "OddUnitPrice",
                    sortable: false,
                    width: 90,
                },
                {
                    text: "Cant",
                    value: "OddQuantity",
                    sortable: false,
                    width: 50,
                },

                {
                    text: "Und",
                    value: "OddUnitMeasurement",
                    sortable: false,
                    width: 50,
                },
                {
                    text: "SubTotal",
                    value: "OddAmount",
                    sortable: false,
                    width: 70,
                    align: "right",
                },
                {
                    text: "Comentario",
                    value: "OddObservation",
                    sortable: false,
                    width: 400,
                },
            ],
            itemsSAP: [],
            orderLocal:{}
        };
    },

    mounted() {
        this.initialize()
    },

    watch: {
        value() {
            this.initialize()
        }
    },

    methods: {
        initialize() {
            _sOrderPurcharse
                .byID({
                        OrdID: this.value,
                    },
                    this.$fun.getUserID()
                )
                .then((r) => {
                    this.orderLocal = r.data.Result
                    this.itemsSAP = this.orderLocal.itemsDetails
                    console.log("detail",r.data);
                });
        },

    },
};
</script>

<style lang="scss" scoped>

</style>
