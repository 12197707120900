let children = [];

children.push({
    path: "/cobranzas/anuncios",
    name: "COB_ADVERTISEMENT",
    component: () =>
        import ("../views/Collection/maintenance/CobAdvertisement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Anuncios", href: "/cobranzas/anuncios" },
        ],
    },
});
children.push({
    path: "/cobranzas/preguntasfrecuentas",
    name: "COB_QUESTION_FRECUENT",
    component: () =>
        import ("../views/Collection/maintenance/CobQuestionFrecuent.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Preguntas frecuentes", href: "/cobranzas/preguntasfrecuentas" },
        ],
    },
});

children.push({
    path: "/finish/payment/",
    name: "COB_FINISH_PAYMENT",
    component: () =>
        import ("../views/PaymentGateway/CheckoutAuth.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Pago Finalizado", disabled: true },
        ],
    },
});

children.push({
    path: "/payment/schedule/",
    name: "COB_SCHEDULE",
    component: () =>
        import ("../views/PaymentGateway/PagSchedule.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cronograma", disabled: true },
        ],
    },
});

children.push({
    path: "/cobranzas/serviciosprogramados",
    name: "COB_SCHEDULED_SERVICE",
    component: () =>
        import ("../views/Collection/maintenance/CobScheduledMessaging.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Servicios programados", href: "/cobranzas/serviciosprogramados" },
        ],
    },
});

children.push({
    path: "/cobranzas/menu",
    name: "COB_MANAGEMENT",
    component: () =>
        import ("../views/Collection/menu/CobIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Menú", href: "/cobranzas/menu" },
        ],
    },
});

children.push({
    path: "/cobranzas/asignarcartera",
    name: "COB_ASSIGN_BRIEFCASE",
    component: () =>
        import ("../views/Collection/assign/CobBbriefcaseAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Cartera", href: "/cobranzas/asignarcartera" },
        ],
    },
});

children.push({
    path: "/cobranzas/tramos",
    name: "COB_SECTIONS",
    component: () =>
        import ("../views/Collection/maintenance/CobSections.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Tramos", href: "/cobranzas/tramos" },
        ],
    },
});


children.push({
    path: "/cobranzas/asignartramos",
    name: "COB_ASSIGN_SECTION",
    component: () =>
        import ("../views/Collection/assign/CobSectionsAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Tramos", href: "/cobranzas/asignartramos" },
        ],
    },
});

children.push({
    path: "/cobranzas/asignarsegmentos",
    name: "COB_ASSIGN_SEGMENT",
    component: () =>
        import ("../views/Collection/assign/CobSegmentAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Segmentos", href: "/cobranzas/asignarsegmentos" },
        ],
    },
});

children.push({
    path: "/cobranzas/segmentos",
    name: "COB_SEGMENT",
    component: () =>
        import ("../views/Collection/maintenance/CobSegment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Segmentos", href: "/cobranzas/segmentos" },
        ],
    },
});


children.push({
    path: "/cobranzas/ignorarclientes",
    name: "COB_CUSTOMER_IGNORED",
    component: () =>
        import ("../views/Collection/CobCustomerIgnore.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Ingorar Clientes", href: "/cobranzas/ignorarclientes" },
        ],
    },
});

children.push({
    path: "/cobranzas/campanias",
    name: "COB_CAMPAIGN",
    component: () =>
        import ("../views/Collection/maintenance/CobCampaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Campañas", href: "/cobranzas/campanias" },
        ],
    },
});

children.push({
    path: "/cobranzas/reportes",
    name: "COB_REPORT",
    component: () =>
        import ("../views/Collection/report/CobManagementReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Reportes", href: "/cobranzas/reportes" },
        ],
    },
});

children.push({
    path: "/cobranzas/tareas",
    name: "COB_TASK",
    component: () =>
        import ("../views/Collection/maintenance/CobTaskPaginated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Tareas", href: "/cobranzas/tareas" },
        ],
    },
});





export { children };