<template>
<div>
    <div>
        <vc-header title="Documentos" />

        <v-card class="mt-1 pt-4 pb-3" style="max-height: 450px;  overflow-y: auto;">
            <v-row style="margin:auto">
    <!--             <v-col cols="12" class="s-col-text">
                    <vc-header title="Sustentos" class="mb-3" />
                    <v-card elevation="0">
                        <v-data-table group-by="ReqDocumentNumber" class="" fixed-header="" dense :items-per-page="-1" disable-sort hide-default-footer :headers="headersFiles" :items="itemsFiles">
                            <template v-slot:item.SecCreate="{ item }">
                                {{$fun.formatDateTimeView(item.SecCreate)}}
                            </template>
                            <template v-slot:item.SecUpdate="{ item }">
                                {{$fun.formatDateTimeView(item.SecUpdate)}}
                            </template>
                            <template v-slot:item.RqdAttach="{ item }">
                                <v-icon color="green" @click="downloadFile(item)">mdi-download</v-icon>
                                {{item.RqdAttach}}
                            </template>
                            <template v-slot:item.StatusApproved="{ item, index }">
                                <v-chip x-small v-if="item.RqdAprroved == 1" color="success">Documento Aprobado</v-chip>
                                <v-chip x-small v-else color="error">Borrador</v-chip>

                            </template>

                        </v-data-table>
                    </v-card>
                </v-col> -->
                <v-col cols="12" class="s-col-text">
                    <vc-header title="Revisiones" class="mb-3 mt-3" />
                    <v-card elevation="0">
                        <v-data-table group-by="ReqDocumentNumber" class="" fixed-header="" dense :items-per-page="-1" disable-sort hide-default-footer :headers="headersDraf" :items="itemsDraf">
                            <!-- <template v-slot:item.RqdAttach="{ item }">
                                <v-icon color="red" @click="deleteFile(item)">mdi-close</v-icon>
                                <v-icon color="green" @click="downloadFile(item)">mdi-download</v-icon>
                                {{ item.RqdAttach }}
                            </template>
                            -->
                            <template v-slot:item.SecCreate="{ item }">
                                {{$fun.formatDateTimeView(item.SecCreate)}}
                            </template>
                            <template v-slot:item.SecUpdate="{ item }">
                                {{$fun.formatDateTimeView(item.SecUpdate)}}
                            </template>
                            <template v-slot:item.RqdAttach="{ item }">
                                
                                <v-btn outlined  color="green" small class="capitalize" @click="downloadFile(item)"><v-icon color="green">mdi-download</v-icon>Descargar doc.</v-btn>
                                {{item.RqdAttach}}
                            </template>
                            <template v-slot:item.StatusApproved="{ item, index }">
                                <v-chip x-small v-if="item.RqdAprroved == 1" color="success">Documento Aprobado</v-chip>
                                <v-chip x-small v-else color="error">Borrador</v-chip>

                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <vc-footer style="box-shadow: -2px 15px 15px 11px" close @close="$emit('close')">
        </vc-footer>
    </div>
</div>
</template>

<script>
import _sHelper from "@/services/HelperService.js";

export default {
    name: '',
    props: {
        itemsContract: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            //itemsAttach: [],
            itemsFiles: [],
            itemsDraf: [],
            headersDraf: [{
                    text: "Nro. Solicitud",
                    value: "ReqDocumentNumber",
                    width: 100
                },
                {
                    text: "Nro. Contrato",
                    value: "ReqNroContract",
                    width: 150
                },
                {
                    text: "Status",
                    value: "StatusApproved"
                },

                {
                    text: "Rev.",
                    value: "TypeRevisionName"
                },
                {
                    text: "Archivo",
                    value: "RqdAttach",
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha C.",
                    value: "SecCreate"
                },
                /* {
                    text: "T. Espera(dd)",
                    value: "TypeRevisionStandbyTime"
                },
                {
                    text: "T. Transcurrido(dd:hh:mm:ss)",
                    value: "TypeRevisionStandbyElapsed"
                }, */
                {
                    text: "Aprobado Por",
                    value: "NtpNameApproved"
                },
                {
                    text: "Fecha Apro.",
                    value: "SecUpdate"
                },

            ],

            headersFiles: [{
                    text: "Nro. Solicitud",
                    value: "ReqDocumentNumber",
                    width: 100
                },
                {
                    text: "Nro. Contrato",
                    value: "ReqNroContract",
                    width: 150
                },

                {
                    text: "Archivo",
                    value: "RqdAttach",
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha C.",
                    value: "SecCreate"
                },

            ],

        };
    },

    created() {
        if (this.itemsContract.length > 0) {
            this.itemsFiles = this.itemsContract.filter(x => x.TypeDocument == 1)
            this.itemsDraf = this.itemsContract.filter(x => x.TypeDocument == 2)
        }
        //console.log("itemsContract", this.itemsContract);
    },

    computed: {

    },

    methods: {
        downloadFile(item) {
            if (item == null) return;
            _sHelper.download(item.RqdAttach, 10).then((r) => {
                this.$fun.download(r.data, item.RqdAttach);
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
