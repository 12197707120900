import Service from "../Service";

const resource = "GenDefinitionDetail/";

export default {
    save(ded, requestID) {
        return Service.post(resource + "save", ded, {
            params: {},
        });
    },
    update(ded, requestID) {
        return Service.put(resource + "update", ded, {
            params: {},
        });
    },
    delete(ded, requestID) {
        return Service.delete(resource + "delete", {
            data: ded,
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {

            },
        });
    },
};